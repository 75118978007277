import { DiveSDK as NewDiveSDK, LogLevels as NewLogLevels } from '@divegames/dive-ts-web-sdk';
import { DiveSDK as OldDiveSDK, LogLevels as OldLogLevels } from 'old-dive-ts-web-sdk';
import { config } from 'config';
import cookie from 'cookie';
import { FindFeatureToggleDocument, FindFeatureToggleQuery } from 'generated/graphql';
import { DiveExperiment } from 'hooks/useDive/types/DiveEvents';
import { createRandomUserId } from 'utils/createRandomUserId';
import { isClientSide } from 'utils/isClientSide';
import { getApolloClient } from './getApolloClient';
import { ABTest, filterActiveABTests } from './filterActiveABTests';
import { prettyEnv } from 'utils/prettyEnv';

const DiveSDK = prettyEnv(config.environment) === 'prod' ? OldDiveSDK : NewDiveSDK;
const LogLevels = prettyEnv(config.environment) === 'prod' ? OldLogLevels : NewLogLevels;

const diveApiUrl = 'https://api.hiber.dive.games';
const diveAnalyticsUrl = 'https://a.hiber.dive.games/hw';

const getExperiments = async (tests: ABTest[]): Promise<DiveExperiment[]> => {
  if (!tests.length) {
    return [];
  }
  const client = getApolloClient();

  const { data } = await client.query<FindFeatureToggleQuery>({
    query: FindFeatureToggleDocument,
    variables: { name: 'A/B' },
  });

  if (!data.findFeatureToggle) {
    return [];
  }

  return filterActiveABTests(data.findFeatureToggle, tests);
};

const initDive = async () => {
  if (!isClientSide()) {
    return;
  }

  let rid = '';

  // this is a workaround to get cookiebot consent to work together with our CSP
  // be defauly cookiebot click event is inline, <a onclick="Cookiebot.dialog.submitConsent()">, which is not allowed by our CSP
  window.CookiebotCallback_OnDialogDisplay = function () {
    const acceptButton = document.querySelector('a.button.accept');
    acceptButton?.removeAttribute('onclick');
    acceptButton?.removeAttribute('href');

    acceptButton?.addEventListener('click', () => {
      // 1. Cookie-bot will remove our rid from the cookie, we want it because without it there will be some disruption in the tracking.
      rid = cookie.parse(document.cookie).rid;
      window.Cookiebot?.dialog?.submitConsent();
    });
  };

  window.CookiebotCallback_OnAccept = function () {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    document.cookie = cookie.serialize('CookieConsent', cookie.parse(document.cookie).CookieConsent, {
      domain: `.${config.domain}`,
      path: '/',
      expires,
      httpOnly: false,
      encode: v => v,
    });

    // 2. Readd the missing rid that cookie bot removed, but only if rid is
    //    missing, this this callback is called every page refresh after cookies
    //    have been accepted.
    if (!cookie.parse(document.cookie).rid) {
      document.cookie = cookie.serialize('rid', rid, {
        maxAge: 9999999999,
        domain: `.${config.domain}`,
        path: '/',
      });
    }
  };

  const dive = DiveSDK.Init({
    AppToken: 'ce97524e-e1a2-44f3-87b6-d8c35efbb49a',
    HashKey: '4A5C232AB89A543BACD4F',
    Logger: { level: LogLevels.DISABLED },
    Environment: config.environment,
    HasIdentityConsent: true,
    AnalyticsURL: diveAnalyticsUrl,
    ShowLogs: false,
    GameVersion: process.env.RELEASE_VERSION,
    ApiURL: diveApiUrl,
    Domain: config.domain,
    ServerURL: 'https://api.hiber.dive.games',
  });

  const cookies = cookie.parse(document.cookie);
  let playerId = cookies.userId || cookies.rid;
  const ABTests = ((cookies._ab && JSON.parse(cookies._ab)) as ABTest[]) || [];

  if (!playerId) {
    playerId = createRandomUserId();
  }

  const diveExperiments = await getExperiments(ABTests);

  dive.SetCustomHeaderParam('experiments', diveExperiments);

  if (!dive.appLauchedSent) {
    dive.SetPlayerId(playerId).Launch();
  }
};

initDive();
