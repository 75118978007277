import { Suspension as SuspensionEntity, useSetSeenSuspensionMutation } from 'generated/graphql';
import { useDialog } from 'hooks/useDialog';
import { useEffectOnce } from 'lib/useOnMount';
import React from 'react';
import { SuspensionDialog } from './SuspensionDialog';

interface SuspensionProps {
  suspension: SuspensionEntity;
}

export const Suspension: React.FC<SuspensionProps> = ({ suspension }) => {
  const [setSeenSuspension] = useSetSeenSuspensionMutation();
  const dialog = useDialog();

  useEffectOnce(() => {
    dialog.open('suspension', {
      name: 'suspended_message',
      origin: 'login',
      description: 'Information about being suspended',
    });
  });

  return (
    <SuspensionDialog
      id="suspension"
      suspension={suspension}
      onClose={() => {
        setSeenSuspension({
          variables: {
            id: suspension.id,
          },
        });
      }}
    />
  );
};
